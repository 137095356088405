import * as React from "react";
import { Layout } from "../components/layout";
import Typed from "react-typed";
import styled from "styled-components";
import { Hero } from "../components/hero";
import { Footer } from "../components/footer";

const SP = styled.p`
  overflow-x: wrap;
  word-wrap: break-word;
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero heroClass="hero-404">
        <h1>404 - Page Not Found</h1>
        <SP>
          <Typed
            className="typed"
            contentType={null}
            strings={[
              "I could not find the page you were looking for, but fonud some pi for you... 3.1415926535897932384626433832795028841971693993751058209749445923078164062862089986280348253421170679821480865132823066470938446095505822317253594081284811174502841027019385211055596446229489549303819644288109756659334461284756482337867831652712019091456485669234603486104543266482133936072602491412737245870066063155881748815209209628292540917153643678925903600113305305488204665213841469519415116094330572703657595919530921861173819326117931051185480744623799627495673518857527248912279381830119491298336733624406566430860213949463952247371907021798609437027705392171762931767523846748184676694051320005681271452635608277857713427577896091736371787214684409012249534301465495853710507922796892589235420199561121290219608640344181598136297747713099605187072113499999983729780499510597317328160963185950244594553469083026425223082533446850352619311881710100031378387528865875332083814206171776691473035982534904287554687311595628638823537875937519577818577805321712268066130019278766111959092164201989",
            ]}
            loop={false}
            showCursor={false}
            typeSpeed={200}
            backDelay={2000}
          />
          &nbsp;
        </SP>
      </Hero>
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
