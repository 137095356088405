import * as React from "react";

export const Layout = ({ children }: { children: any }) => {
  const isRootPath = `${__PATH_PREFIX__}/` === "/";
  return (
    <div
      className="global-wrapper"
      data-is-root-path={isRootPath}
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-delay="0"
    >
      {children}
    </div>
  );
};
