import * as React from "react";
import styled from "styled-components";


const SFooter = styled.footer`
  background: #f7f8f9;
  color: #45505b;
  font-size: 14px;
  text-align: center;
  padding: 10px 0px;
  bottom: 0;
  width: 100%;
  margin-top: -72px;

  h3 {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0 0 15px 0;
  }

  p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 5px 0 5px 0;
  }

  .copyright {
    margin: 0 0 5px 0;
  }

  .credits {
    font-size: 13px;
  }
`;


export class Footer extends React.PureComponent {
  public render() {
    return (
      <SFooter>
        <div className="d-flex justify-content-center">
          <div className="">
            <span className="copyright">
              Copyright © 2021 Poetry of the Machine
            </span>
            <p>This is AI generated poetry.</p>
          </div>
        </div>
      </SFooter>
    );
  }
}