import * as React from "react";
import Link from "gatsby-link";
import { getMachineData } from "../hooks/machine-data";
import { Layout } from "../components/layout";
import { Navbar } from "../components/navbar";
import { Hero } from "../components/hero";
import { Footer } from "../components/footer";

const Machines = () => {
  const machines = getMachineData();

  return (
    <Layout>
      <Navbar />
      <Hero heroClass="hero-machine-list">
        {machines.map((machine) => (
          <Link to={machine.slug}>
            <h2>{machine.name}</h2>
          </Link>
        ))}
      </Hero>
      <Footer />
    </Layout>
  );
};

export default Machines;
