import * as React from "react";

export const Hero = ({ heroClass, children }: { heroClass: string, children: any }) => {
  const isRootPath = `${__PATH_PREFIX__}/` === "/";
  return (
      <section
        id="hero"
        className={heroClass + " d-flex flex-column justify-content-center"}
      >
        <div
          className="container aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          {children}
        </div>
      </section>
  );
};
