import * as React from "react";
import { Layout } from "../components/layout";
import styled from 'styled-components'
import { Link } from "gatsby";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { Recommender } from "../components/recommender";
import { ShareButtons } from "../components/share-buttons";

const SPoem = styled.div`
@media (min-width: 768px) {
  padding-left: 160px;
  p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  padding-left: 20px;
}

.poem {
  border-left: 1px solid #F2F3F5;
  padding-left: 10px;

  p {
    font-family: Georgia,serif;
    margin-bottom: 10px;
  }
}

padding-bottom: 50px;

a {
  color: #424345;
  font-weight: bold;
  font-size: 20px;
}
`

const PoemWrapper = styled.div`
  min-height: 100vh;
  margin-bottom: 72px;
  margin-top: 30px;
`

export default function Poem({ pageContext }: any) {

  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <Layout>
      <Navbar />
      <PoemWrapper className="d-flex align-items-center">
        <SPoem className="d-flex flex-column align-self-center justify-content-center">
          <h1 className="poem-title" data-aos-delay="100">
            {pageContext.poem.title}
          </h1>
          <div
            className="poem"
            dangerouslySetInnerHTML={{ __html: pageContext.poem.html }}
          />

          <ShareButtons url={url} title={pageContext.poem.title} description={pageContext.poem.excerpt} />
          {/* <Recommender poem={poem} /> */}
          <Recommender poem={undefined} />
          <div className="d-flex">
            <div className="flex-column">
              <Link to={pageContext.prev}>Previous</Link>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="flex-column justify-content-center">
              <Link to={pageContext.next}>Next</Link>
            </div>
          </div>
        </SPoem>

      </PoemWrapper>
      <Footer />
    </Layout>
  );
}