import * as React from "react";
import { graphql, withPrefix } from "gatsby";
import Typed from "react-typed";
import { Layout } from "../components/layout";
import { Navbar } from "../components/navbar";
import { Hero } from "../components/hero";
import { Footer } from "../components/footer";

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

export default class extends React.Component<IndexPageProps, {}> {
  constructor(props: IndexPageProps, context: any) {
    super(props, context);
  }

  public render() {
    // &nbsp; at end prevents visual alignment issues at the start of typing
    return (
      <Layout>
        <Navbar />
        <Hero heroClass="hero-index">
          <h1>Poetry of the Machine</h1>
          <p>
            <Typed
              className="typed"
              contentType={null}
              strings={["AI Generated Poetry.", "Curated By Humans."]}
              loop={false}
              showCursor={false}
              typeSpeed={60}
              backDelay={2000}
            />
            &nbsp;
          </p>
        </Hero>
        <Footer />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
