import * as React from "react";
import {
    FacebookShareButton,
    TwitterShareButton,
    RedditShareButton,
} from 'react-share';
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiFillTwitterCircle } from "@react-icons/all-files/ai/AiFillTwitterCircle";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight";
import { AiFillRedditCircle } from "@react-icons/all-files/ai/AiFillRedditCircle";
export const ShareButtons = ({ url, title, description }: { url: any, title: string, description: string }) => {
    return (
        <div className="post-meta-share-icons">
            <AiFillHeart size={22} color='#DD0000' />
            <AiOutlineArrowRight size={22}  color='#F2F3F5' />
            <FacebookShareButton url={url} quote={description}>
                <AiFillFacebook size={22} />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={description}>
                <AiFillTwitterCircle size={22} />
            </TwitterShareButton>
            <RedditShareButton url={url} title={title}>
                <AiFillRedditCircle size={22} />
            </RedditShareButton>
        </div>
    );
};
