import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { getMachineData } from "../hooks/machine-data";
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH";

const Navigation = styled.div`
  #header {
    background-color: #fff;
  }
`;

const Toggle = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Navbox = styled.nav`
  //display: flex;
  height: 100%;
  align-items: center;
  overflow: hidden;
  padding: 0;
  display: block;
  position: relative;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul > li {
    position: relative;
    white-space: nowrap;
  }

  a {
  }

  a:hover,
  .active,
  li:hover > a {
    color: #fff;
    background: #0563bb;
  }

  a:hover span,
  .active span,
  li:hover > a span {
    color: #fff;
  }

  a:hover,
  li:hover > a {
    width: 100%;
    color: #fff;
  }

  a:hover span,
  li:hover > a span {
    display: block;
  }

  @media (min-width: 768px) {
    a {
      width: 56px;
    }
    a span {
      display: none;
      color: #fff;
    }
  }
  // New Stuff Ends HEre
  @media (max-width: 768px) {
    width: 300px;
    transition: all 0.5s;
    left: ${(props) => (props.open ? "-120%" : "0")};
    height: 100%;
  }
`;

const Hamburger = styled.div`
  position: fixed;
  top: 15px;
  right: 10px;
  background-color: #111;
  width: 25px;
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  border: none;

  transform: ${(props) => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 25px;
    height: 2px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? "0" : "1")};
    transform: ${(props) => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;

const NavItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #45505b;
  padding: 10px 20px;
  margin-bottom: 8px;
  font-size: 15px;
  border-radius: 60px;
  background: #f2f3f5;
  height: 56px;
  overflow: hidden;
  transition: 0.3s;

  i {
    font-size: 20px;
  }
  span {
    padding: 0 20px 0 7px;
    color: #45505b;
    white-space: nowrap;
  }
`;

export const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const machineData = getMachineData();
  return (
    <header
      id="header"
      className={
        (navbarOpen ? "navbar-active " : "") +
        "d-flex flex-column justify-content-center"
      }
    >
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      <Navigation className={navbarOpen ? "navbar-active" : ""}>
        {navbarOpen ? (
        // <NavItems />
        <Navbox className="navbar">
        {machineData.map((machine) => (
          <li key={machine.className}>
            <NavItem
              className={machine.className + " nav-link scrollto"}
              to={machine.slug}
            >
              <machine.icon />
              <span>{machine.name}</span>
            </NavItem>
          </li>
        ))}
        <li key="all">
          <NavItem className="all nav-link" to="/machines">
            <FaEllipsisH />
            <span>List them all</span>
          </NavItem>
        </li>
      </Navbox>
        ) : (
          <Navbox open className="navbar">
            {machineData.map((machine) => (
              <li key={machine.className}>
                <NavItem
                  className={machine.className + " nav-link scrollto"}
                  to={machine.slug}
                >
                  <machine.icon />
                  <span>{machine.name}</span>
                </NavItem>
              </li>
            ))}
            <li key="all">
              <NavItem className="all nav-link" to="/machines">
                <FaEllipsisH />
                <span>List them all</span>
              </NavItem>
            </li>
          </Navbox>
        )}
      </Navigation>
    </header>
  );
};