import * as React from "react";

export const Recommender = ({ poem }: { poem: any }) => {
  if(poem !== undefined) {
  return (
      <div>
          hello world
      </div>
  );
  } else {
    return (<></>);
  }
};
