import { useStaticQuery, graphql } from "gatsby";


import { IconType } from "@react-icons/all-files/lib/esm/iconBase";
import { FaBeer } from "@react-icons/all-files/fa/FaBeer";
import { GiCoffeePot } from "@react-icons/all-files/gi/GiCoffeePot";
import { GiPsychicWaves } from "@react-icons/all-files/gi/GiPsychicWaves";
import { GiVacuumCleaner } from "@react-icons/all-files/gi/GiVacuumCleaner";
import { GrPersonalComputer } from "@react-icons/all-files/gr/GrPersonalComputer";
import { GiBubblingBowl } from "@react-icons/all-files/gi/GiBubblingBowl";
import { GiTv } from "@react-icons/all-files/gi/GiTv";
import { GiBurn } from "@react-icons/all-files/gi/GiBurn";
import { HiClock } from "@react-icons/all-files/hi/HiClock";
import { BiFridge } from "@react-icons/all-files/bi/BiFridge";
import { FaBlender } from "@react-icons/all-files/fa/FaBlender";
import { FaToilet } from "@react-icons/all-files/fa/FaToilet";

const machineTo = function (machine: string) {
  let cMachine = machine.toLowerCase();
  if (cMachine === "microwave") {
    return {
      icon: GiPsychicWaves,
      title: "Microwaves"
    }
  }
  if (cMachine === "coffee pot") {
    return {
      icon: GiCoffeePot,
      title: "Coffee Pots"
    }
  }
  if (cMachine === "computer") {
    return {
      icon: GrPersonalComputer,
      title: "Computers"
    }
  }
  if (cMachine === "blender") {
    return {
      icon: FaBlender,
      title: "Blenders"
    }
  }
  if (cMachine === "toaster") {
    return {
      icon: GiBurn,
      title: "Toasters"
    }
  }
  if (cMachine === "dishwasher") {
    return {
      icon: GiBubblingBowl,
      title: "Dishwashers"
    }
  }
  if (cMachine === "television") {
    return {
      icon: GiTv,
      title: "Televisions"
    }
  }
  if (cMachine === "clock") {
    return {
      icon: HiClock,
      title: "Clocks"
    }
  }
  if (cMachine === "vacuum") {
    return {
      icon: GiVacuumCleaner,
      title: "Vacuums"
    }
  }
  if (cMachine === "fridge") {
    return {
      icon: BiFridge,
      title: "Fridges"
    }
  }
  if (cMachine === "toilet") {
    return {
      icon: FaToilet,
      title: "Toilets"
    }
  }
  return {
    icon: FaBeer,
    title: "Beers Me Bugs"
  }
};

export interface Machine {
  name: string;
  slug: string;
  className: string;
  icon: IconType;
  title: string;
}


export const getMachineDataFromMetadata = (metadata : string) => {
  let machine = metadata.split("|")[0];
  return {
    name: machine,
    slug: metadata.split("|")[1],
    className: metadata.split("|")[2],
    ...machineTo(machine),
  }
}


export const getMachineData = () => {
  const machineDataQuery = useStaticQuery(graphql`
    query NavBarQuery {
      poems: allMarkdownRemark {
        machines: distinct(field: fields___machine_metadata)
      }
    }
  `);
  const machinesMetadata: Machine[] = [];
  machineDataQuery.poems.machines.forEach((metadata: any) => {
    machinesMetadata.push(getMachineDataFromMetadata(metadata));
  });
  return machinesMetadata;
};