import * as React from "react";
import Link from "gatsby-link";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";

const Poems = ({ data }: any) => {
  const { poems } = data.poems;
  // console.log("Poems: %o", poems);
  return (
    <Layout heroClass="">
      <div>
        <h1>Poemmss</h1>

        {poems.map((poem: any) => (
          <article key={poem.id}>
            <Link to={poem.fields.slug}>
              <h2>{poem.frontmatter.title}</h2>
            </Link>
            <small>
              {poem.frontmatter.author}, {poem.frontmatter.date}
            </small>
            <p>{poem.excerpt}</p>
          </article>
        ))}
      </div>
    </Layout>
  );
};
// https://blog.logrocket.com/creating-a-gatsby-blog-from-scratch/
// Use http://localhost:8000/___graphql to figure out the graphql
export const pageQuery = graphql`
  query Poems {
    poems: allMarkdownRemark {
      poems: nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
        excerpt
        id
      }
    }
  }
`;

export default Poems;
