import * as React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import Typed from "react-typed";
import { Hero } from "../components/hero";
import { getMachineDataFromMetadata } from "../hooks/machine-data";
import { Layout } from "../components/layout";
import { Navbar } from "../components/navbar";
import styled from 'styled-components'
import { Footer } from "../components/footer";

export const query = graphql`
  query PoemsByMachine($machine: String!) {
    poems: allMarkdownRemark(
      filter: { frontmatter: { machine: { eq: $machine } } }
      sort: {fields: [frontmatter___machine, frontmatter___date, frontmatter___title]}
    ) {
      poems: nodes {
        frontmatter {
          date(fromNow: true)
          title
          machine
        }
        fields {
          slug
          machine_metadata
        }
        excerpt
        id
      }
    }
  }
`;

const PoemList = styled.div`
@media (min-width: 768px) {
  padding-left: 160px;
  p {
    font-size: 18px;
  }
}

padding-bottom: 72px;

@media (max-width: 768px) {
  padding-left: 20px;
}

.poem {
  border-left: 1px solid #F2F3F5;
  padding-left: 10px;
  margin-bottom: 40px;

  p {
    font-family: Georgia,serif;
    margin-bottom: 10px;
  }
}

.poem {
padding-bottom: 50px;
font-family: Georgia,serif;
}
a {
  color: #45505b;
}
`

export default function Machine({ data, pageContext }: any) {
  const { poems } = data.poems;
  const single = poems[0];
  const metadata = getMachineDataFromMetadata(single.fields.machine_metadata);
  return (
    <Layout>
      <Navbar />
      <Hero heroClass="">
        <h1 className="poem-title" data-aos-delay="100">
          {single.frontmatter.machine}
        </h1>
      </Hero>
      <PoemList>
        <div className='d-flex flex-wrap'>
          {poems.map((poem: any) => (
            <div className='p-2 poem'>
              <h2>
                <Link to={poem.fields.slug}>{poem.frontmatter.title}</Link>
              </h2>
              <div>{poem.excerpt}</div>
            </div>
          ))}
        </div>
        {/* <Link to={pageContext.prev}>Previous</Link>
        <Link to={pageContext.next}>Next</Link> */}
      </PoemList>
      <Footer />
    </Layout>
  );
}
